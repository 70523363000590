import { defaultInlineImagesSettings } from '../helpers/documentHelpers'

export default class MatchingSettings {
    constructor() {
        this.match_type = 'line'
        this.line_up_dots = 1
        this.blank_position = 'before'
        this.align_clues = 'left'
        this.letter_scramble = 0
        this.number_of_columns = 2
        this.display_index = true
        this.images = { ...defaultInlineImagesSettings }
        this.images.size = 96
    }
}
